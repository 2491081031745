<template>
  <div>
    <form @submit.prevent="createNews" class="">
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="title" class="form-label"> Titre :</label>
            <input
              type="text"
              id="title"
              v-model="newsArticle.title"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="publicationDate" class="form-label">
              Date publication :</label
            >
            <input
              type="date"
              id="publicationDate"
              v-model="newsArticle.publicationDate"
              class="form-control"
              required
            />
          </div>

          <div class="mb-3">
            <label for="content" class="form-label"> Text :</label>
            <textarea
              id="content"
              v-model="newsArticle.content"
              class="form-control"
              rows="15"
              required
            ></textarea>
          </div>

          <div class="mb-3">
            <label for="photo" class="form-label"> Photo:</label>
            <input
              type="file"
              id="photo"
              @change="handleImageUpload"
              class="form-control"
              accept="image/*"
            />
          </div>
        </div>
        <div class="col-6 text-end">
          <div class="mb-3 text-end">
            <label for="title" class="form-label"> Titre Arabe :</label>
            <input
              type="text"
              id="title"
              v-model="newsArticle.titleAr"
              class="form-control text-end"
              required
            />
          </div>

          <div class="mb-3">
            <label for="publicationDate" class="form-label">
              Date publication :</label
            >
            <input
              type="date"
              id="publicationDate"
              v-model="newsArticle.publicationDate"
              class="form-control text-end"
              disabled
            />
          </div>

          <div class="mb-3">
            <label for="content" class="form-label"> Text Arabe :</label>
            <textarea
              id="content"
              v-model="newsArticle.contentAr"
              class="form-control text-end"
              rows="15"
              required
            ></textarea>
          </div>

          <div class="mb-3 text-start">
            <label class="form-label"> Événement à venir ou publication </label>
            <select v-model="newsArticle.category" class="form-select">
              <option value="news" selected>Publication</option>
              <option value="event">Événement à venir</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div>
            <span class="fw-bold fs-4">
              {{ newsArticle.title }}
            </span>
          </div>
          <div class="w-100">
            <img :src="newsArticle.imageUrl" alt="" class="w-100" />
          </div>

          <div class="">
            <br />
            {{ newsArticle.publicationDate }}
          </div>
          <div class="">
            <p v-html="newsArticle.content"></p>
          </div>
        </div>
        <div class="col-6 text-end">
          <div>
            <span class="fw-bold fs-4">
              {{ newsArticle.titleAr }}
            </span>
          </div>
          <div class="w-100">
            <img :src="newsArticle.imageUrl" alt="" class="w-100" />
          </div>

          <div class="">
            <br />
            {{ newsArticle.publicationDate }}
          </div>
          <div class="">
            <p v-html="newsArticle.contentAr"></p>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">
        Enregistrer l'article
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsArticle: {
        title: "",
        titleAr: "",
        content: "",
        contentAr: "",
        image: null,
        publicationDate: new Date().toLocaleDateString(),
      },
    };
  },
  computed: {
    photoURL() {
      return this.newsArticle.photo
        ? URL.createObjectURL(this.newsArticle.photo)
        : "";
    },
  },
  methods: {
    async createNews() {
      const formData = new FormData();
      formData.append("title", this.newsArticle.title);
      formData.append("titleAr", this.newsArticle.titleAr);
      formData.append("content", this.newsArticle.content);
      formData.append("contentAr", this.newsArticle.contentAr);
      formData.append("publicationDate", this.newsArticle.publicationDate);
      formData.append("image", this.newsArticle.image);
      formData.append("publicationDate", this.newsArticle.publicationDate);
      formData.append("category", "news");

      await this.$store.dispatch("news/create", formData);
      await (this.newsArticle = {
        title: "",
        content: "",
        image: null,
        publicationDate: new Date().toLocaleDateString(),
      });
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      this.newsArticle.image = file;
      this.newsArticle.imageUrl = URL.createObjectURL(file); // Create a URL for the image
    },
  },
};
</script>
